<template>
    <header class="page-title">
        <h1><i class="bi bi-bar-chart-line-fill"></i> 売上粗利推移レポート</h1>
    </header>

    <section class="section">
        <form class="row mb-3" @submit.prevent="fetch()">
            <div class="col-6">
                <div class="input-group">
                    <form-input-month v-model="month_from" required></form-input-month>
                    <span class="input-group-text">〜</span>
                    <form-input-month v-model="month_to" required></form-input-month>
                </div>
            </div>
            <div class="col-3">
                <form-select v-model="dimension" :options="dimensions" model_type="string"></form-select>
            </div>
            <div class="col-3">
                <form-select v-model="span" :options="spans"></form-select>
            </div>
            <div class="col-4">
                <button type="submit" class="btn btn-primary">表示</button>
            </div>
        </form>

        <div class="bg-light p-3 mb-3">
            <h4>売上</h4>
            <bar-chart :dataset="sales_chart_data" :loading="loading" :stack="true"></bar-chart>
            <h4>粗利</h4>
            <bar-chart :dataset="profit_chart_data" :loading="loading" :stack="true"></bar-chart>
        </div>

        <table class="table table-bordered">
            <thead class="table-dark">
                <tr>
                    <th class="text-center"></th>
                    <th v-for="obj of dimension_objects" :key="obj.key" class="text-center">{{ obj.label }}</th>
                </tr>
            </thead>
            <tbody v-if="!loading">
                <tr v-for="month of months" :key="month">
                    <td class="">
                        {{ month }}
                        <span v-if="span !== 1">〜</span>
                    </td>
                    <td v-for="obj of dimension_objects" :key="obj.key" class="text-end">
                        <div class="fw-bold">{{ this.$helper.number(find_sales(obj.key, month)) }}</div>
                        <div class="">{{ this.$helper.number(find_profit(obj.key, month)) }}</div>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="i in Array(10)" :key="i">
                    <td class=""><text-loader :width="6"></text-loader></td>
                    <td v-for="obj of dimension_objects" :key="obj.key" class="text-end">
                        <text-loader :width="6"></text-loader><br>
                        <text-loader :width="6"></text-loader>
                    </td>
                </tr>
            </tbody>
            <tfoot v-if="!loading">
                <tr>
                    <td>合計</td>
                    <td v-for="obj of dimension_objects" :key="obj.key" class="text-end">
                        <div class="fw-bold">{{ this.$helper.number(total_sales(obj.key)) }}</div>
                        <div class="">{{ this.$helper.number(total_profit(obj.key)) }}</div>
                    </td>
                </tr>
            </tfoot>
            <tfoot v-else>
                <tr>
                    <td class=""><text-loader :width="6"></text-loader></td>
                    <td v-for="obj of dimension_objects" :key="obj.key" class="text-end">
                        <text-loader :width="6"></text-loader><br>
                        <text-loader :width="6"></text-loader>
                    </td>
                </tr>
            </tfoot>
        </table>

    </section>
</template>

<script>
import FormInputMonth from '@/components/forms/FormInputMonth';
import FormSelect from '@/components/forms/FormSelect';
import TextLoader from '@/components/tools/TextLoader';
import Category from "@/models/entities/category";
import Department from "@/models/entities/department";
import User from "@/models/entities/user";
import BarChart from '@/components/charts/BarChart';

export default {
    name: 'StatsTransition',
    components: {
        FormInputMonth,
        FormSelect,
        TextLoader,
        BarChart,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            loading: true,
            dimension: 'category_1',
            span: 1,
            data: [],
            months: [],
            dimensions: [
                {value: 'category_1', label: '案件分類1別'},
                {value: 'category_2', label: '案件分類2別'},
                {value: 'category_3', label: '案件分類3別'},
                {value: 'category_4', label: '案件分類4別'},
                {value: 'user', label: '担当者別'},
                {value: 'department', label: '部門別'},
            ],
            spans: [
                {value: 1, label: '月ごと'},
                {value: 3, label: '四半期ごと'},
                {value: 6, label: '半期ごと'},
                {value: 12, label: '年度ごと'},
            ],
            categories_1: [],
            categories_2: [],
            users: [],
            departments: [],
        }
    },
    mounted() {
        this.fetch();

        this.$http.get('/list/taxonomy/1/category')
        .then((response) => {
            this.categories_1 = response.data.map((row) => {return new Category(row)});
        })

        this.$http.get('/list/taxonomy/2/category')
        .then((response) => {
            this.categories_2 = response.data.map((row) => {return new Category(row)});
        })

        this.$http.get('/list/taxonomy/3/category')
        .then((response) => {
            this.categories_3 = response.data.map((row) => {return new Category(row)});
        })

        this.$http.get('/list/taxonomy/4/category')
        .then((response) => {
            this.categories_4 = response.data.map((row) => {return new Category(row)});
        })

        this.$http.get('/list/user')
        .then((response) => {
            this.users = response.data.map((row) => {return new User(row)});
        })

        this.$http.get('/list/department')
        .then((response) => {
            this.departments = response.data.map((row) => {return new Department(row)});
        })
    },
    methods: {
        fetch() {
            this.loading = true;
            this.$http.get('/stats/transition', {params: {
                month_from: this.month_from,
                month_to: this.month_to,
                dimension: this.dimension,
                span: this.span,
            }})
            .then((response) => {
                this.data = [];
                this.data_dimensions = [];
                this.data_months = [];

                this.data = response.data;
                let data_months = this.data.map((row) => row.month);
                this.months = [...new Set(data_months)]; // ユニークに

                this.loading = false;
            });
        },
        find_sales(dimension_key, month) {
            let founds = this.data.filter((row) => row.dimension_key === dimension_key && row.month === month);
            if (founds.length === 0) {
                return 0;
            }
            return founds[0].sales_price;
        },
        find_profit(dimension_key, month) {
            let founds = this.data.filter((row) => row.dimension_key === dimension_key && row.month === month);
            if (founds.length === 0) {
                return 0;
            }
            return founds[0].gross_profit;
        },
        total_sales(dimension_key) {
            let filtered = this.data.filter((row) => row.dimension_key === dimension_key);
            return filtered.reduce((total, row) => total + row.sales_price, 0);
        },
        total_profit(dimension_key) {
            let filtered = this.data.filter((row) => row.dimension_key === dimension_key);
            return filtered.reduce((total, row) => total + row.gross_profit, 0);
        },
    },
    computed: {
        month_from: {
            get() {
                return this.$store.state.condition.month_from;
            },
            set(value) {
                this.$store.commit('condition/setMonthFrom', value);
            },
        },
        month_to: {
            get() {
                return this.$store.state.condition.month_to;
            },
            set(value) {
                this.$store.commit('condition/setMonthTo', value);
            },
        },
        dimension_objects() {
            let dimension_objects = [];
            if (this.dimension === 'category_1') {
                dimension_objects = this.categories_1.map((category) => {
                    return {
                        key: category.category_id,
                        label: category.category_name,
                    };
                });
            } else if (this.dimension === 'category_2') {
                dimension_objects = this.categories_2.map((category) => {
                    return {
                        key: category.category_id,
                        label: category.category_name,
                    };
                });
            } else if (this.dimension === 'category_3') {
                dimension_objects = this.categories_3.map((category) => {
                    return {
                        key: category.category_id,
                        label: category.category_name,
                    };
                });
            } else if (this.dimension === 'category_4') {
                dimension_objects = this.categories_4.map((category) => {
                    return {
                        key: category.category_id,
                        label: category.category_name,
                    };
                });
            } else if (this.dimension === 'user') {
                dimension_objects = this.users.map((user) => {
                    return {
                        key: user.user_id,
                        label: user.user_name,
                    };
                });
            } else if (this.dimension === 'department') {
                dimension_objects = this.departments.map((department) => {
                    return {
                        key: department.department_id,
                        label: department.department_name,
                    };
                });
            }

            dimension_objects.push({
                key: 0,
                label: "未設定",
            });

            return dimension_objects;
        },

        sales_chart_data() {
            return this.months.map(month => {
                return {
                    label: month,
                    values: this.dimension_objects.map((obj) => {
                        return {
                            key: obj.label,
                            value: this.find_sales(obj.key, month)
                        }
                    }),
                }
            })
        },
        profit_chart_data() {
            return this.months.map(month => {
                return {
                    label: month,
                    values: this.dimension_objects.map((obj) => {
                        return {
                            key: obj.label,
                            value: this.find_profit(obj.key, month)
                        }
                    }),
                }
            })
        }
    },
}
</script>

<style scoped>
</style>
