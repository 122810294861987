<template>
    <Bar
        v-if="loading"
        :chart-options="chartOptions"
        :chart-data="loadingData"
        :width="2000"
        :height="400"
    />
    <Bar
        v-else-if="dataset.length > 0"
        :chart-options="chartOptions"
        :chart-data="chartData"
        :width="2000"
        :height="400"
    />
    <div v-else class="d-flex align-items-center justify-content-center" style="aspect-ratio: 5;">
        <strong class="text-secondary">NO DATA</strong>
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
    name: 'BarChart',
    components: { Bar },
    props: {
        dataset: {
            type: Array,
            default: () => [] // {label, values: [{key, value}]} オブジェクトの配列
        },
        loading: {
            type: Boolean,
            default: false
        },
        stack: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            loadingData: {
                labels: ['...', '...', '...', '...', '...', '...'],
                datasets: [
                    {
                        label: 'Loading...',
                        backgroundColor: '#EEE',
                        data: [1000000, 1000000, 1000000, 1000000, 1000000, 1000000],
                    },
                ],
            }
        }
    },
    computed: {
        chartData() {
            if (this.dataset.length === 0) {
                return {
                    labels: [],
                    datasets: [],
                }
            }

            let labels = this.dataset.map(row => row.label);
            let value_labels = this.dataset[0].values.map(row => row.key);
            let datasets = [];
            for (let i = 0; i < value_labels.length; i++) {
                datasets.push({
                    label: value_labels[i],
                    backgroundColor: this.colors[i],
                    data: this.dataset.map(row => row.values[i].value),
                });
            }

            return {
                labels,
                datasets
            };
        },
        chartOptions() {
            return {
                responsive: true,
                scales: {
                    x: {
                        stacked: this.stack,
                    },
                    y: {
                        stacked: this.stack,
                    }
                },
                barPercentage: 0.5,
            }
        },
        colors() {
            const palette = [
                '#de6474',
                '#deca64',
                '#ce64de',
                '#b1de64',
                '#6474de',
                '#de9164',
                '#64dece',
                '#de64b1',
                '#74de64',
                '#9164de',
                '#dece64',
            ];

            return palette;
        }
    }
}
</script>